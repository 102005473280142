jQuery(document).ready(function($) {
  $('.js-toggle-menu').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('active');
    $('.js-primary-navigation').toggleClass('is-visible');
  });
  // Close dropdowns on outside click
  $(document).on('click touchend', function(event) {
    if ( ! $(event.target).closest('.site-header-inner').length && ! $(event.target).closest('.primary-navigation').length) {
      $('.js-toggle-menu').removeClass('active');
      $('.primary-navigation').removeClass('is-visible');
    }
  });

  $('.js-toggle-mobile-search').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('active');
    $('.site-header-search').toggleClass('is-visible');
    if ( $(this).hasClass('active') ) {
      $('.site-header-search-field').focus().select();
    }
  });

  $('.js-toggle-dropdown').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('active');
    var $dropdown = $(this).next('.js-dropdown');
    if ( $dropdown.hasClass('is-visible') ) {
      $('.js-dropdown').removeClass('is-visible');
    } else {
      $('.js-dropdown').not($dropdown).removeClass('is-visible');
      $dropdown.toggleClass('is-visible');
    }
  });

  $('.js-fitvids').fitVids();

  $('.js-read-more').on('click', function(event) {
    event.preventDefault();
    $(this).toggleClass('drop-link--up');
    $('.car-description').toggleClass('is-collapsed');
  });

  // Vote for review
  $('body').on('click', '.js-vote-helpful', function(event) {
    event.preventDefault();
    var link = $(this);
    var url = $(this).attr('href');
    $.post(url, false, function(data, textStatus, xhr) {
      link.fadeOut('200', function() {
        link.html(data).fadeIn(300);
      });
    });
  });

  $.get('/api/car-autocomplete', function(data) {
    var input = $('.js-car-autocomplete');
    var list = JSON.parse(data);
    new Awesomplete( input[0], {
      list: list,
      sort: false,
      replace: function(text) {
        this.input.value = text;
      },
    });
    input[0].addEventListener("awesomplete-select", function(e){
      window.location.href = e.text.value;
    }, false);
  });

  $('.js-slick').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '20px',
        }
      }
    ],
  });

  $('.js-slick-video').slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    dots: false,
    arrows: false,
    touchMove: false,
    centerMode: true,
    centerPadding: '120px',
    responsive: [
      {
        breakpoint: 1024,
        centerMode: true,
        centerPadding: '40px',
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 720,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px',
        }
      }
    ],
  });

  $('.js-slick-next').on('click', function(event) {
    event.preventDefault();
    $('.js-slick-' + $(this).data('slider')).slick('slickNext');
  });
  $('.js-slick-prev').on('click', function(event) {
    event.preventDefault();
    $('.js-slick-' + $(this).data('slider')).slick('slickPrev');
  });
});
